import React, { createContext, useContext, useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {checkProPlan, fetchDocuments, fetchGenerations, fetchVideos} from "../utils/redux-helpers";
import {
    addDocument,
    setDocuments,
    setDocumentsGeneration,
    setIsNewFeatureOpen,
    setIsProPlan,
    setVideos
} from "./reducers";
import {
    getDocumentsInStorage,
    getDocumentsInStorageGeneration, getDocumentsInStorageVideo
} from "../utils/local-storage-helpers";
import axios from "axios";
import {SERVER_IP} from "../utils/constants";
import {getFeatureFlag, setFeatureFlag, transferFromGuest, transferFromGuestVideo} from "../utils/api";
import { getFingerprint } from "@thumbmarkjs/thumbmarkjs";
import {fetchMultidocs} from "../utils/multi-doc-helpers";
import {useServerIP} from "./ServerIPContext";

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userID, setUserID] = useState(null);
    const [isThinking, setIsThinking] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isThinkingGeneration, setIsThinkingGeneration] = useState(false);
    const [isTypingGeneration, setIsTypingGeneration] = useState(false);
    const [isThinkingVideo, setIsThinkingVideo] = useState(false);
    const [isTypingVideo, setIsTypingVideo] = useState(false);

    const [playgroundDocumentsGeneration, setPlaygroundDocumentsGeneration] = useState([]);
    const [playgroundDocuments, setPlaygroundDocuments] = useState([]);
    const [playgroundDocumentsVideo, setPlaygroundDocumentsVideo] = useState([]);
    const dispatch = useDispatch();
    const { serverIPs, setServerIPs, isDev } = useServerIP();
    // const documents = useSelector(state => state.documents.savedDocuments)

    useEffect(() => {
        const storedUserID = localStorage.getItem("userID");
        console.log("Initial load, userID: ", storedUserID)

        if (localStorage.getItem('fingerprint') === null) {
            getFingerprint()
                .then((result) => {
                    localStorage.setItem("fingerprint", result);
                    // call the route

                })
                .catch((error) => {
                    console.error('Error getting fingerprint:', error);
                });
        }


        if (storedUserID !== null) {
            console.log(localStorage.getItem("email"))
            console.log(localStorage.getItem("firstName"))
            console.log(localStorage.getItem("lastName"))
            console.log("Stored UserID is not null")
            setIsLoggedIn(true);
            setUserID(storedUserID);
            fetchDocuments(storedUserID, serverIPs.SERVER_IP).then(r => {
                dispatch(setDocuments(r.documents));
            })
            fetchVideos(storedUserID, serverIPs.SERVER_IP_VIDEO).then(r => {
                dispatch(setVideos(r.videos));
            })
            fetchMultidocs(storedUserID, serverIPs.SERVER_IP_MULTIDOC).then(r => {
                if (r === undefined) {
                    dispatch(setDocumentsGeneration([]));
                } else {
                    dispatch(setDocumentsGeneration(r.multidocs));
                }
            })
            checkProPlan(storedUserID, serverIPs.SERVER_IP).then(r => {
                dispatch(setIsProPlan(r.isProPlan));
                localStorage.setItem("isProPlan", r.isProPlan.toString());

                if (r.isProPlanByDomain) {
                    localStorage.setItem("isProPlanByDomain", r.isProPlanByDomain.toString());
                } else {
                    localStorage.setItem("isProPlanByDomain", "false");
                }
            })
        } else {
            if (localStorage.getItem("documents") === null) {
                localStorage.setItem("documents", JSON.stringify({savedDocuments: []}));
                dispatch(setDocuments([]));
            } else {
                setPlaygroundDocuments(getDocumentsInStorage());
                dispatch(setDocuments(getDocumentsInStorage()));
            }

            if (localStorage.getItem("videos") === null) {
                localStorage.setItem("videos", JSON.stringify({savedVideos: []}));
                dispatch(setVideos([]));
            } else {
                setPlaygroundDocumentsVideo(getDocumentsInStorageVideo());
                dispatch(setVideos(getDocumentsInStorageVideo()));
            }

            if (localStorage.getItem("generations") === null) {
                localStorage.setItem("generations", JSON.stringify({savedGenerations: []}));
                dispatch(setDocumentsGeneration([]));
            } else {
                setPlaygroundDocumentsGeneration(getDocumentsInStorageGeneration());
                dispatch(setDocumentsGeneration(getDocumentsInStorageGeneration()));
            }
        }
    }, []);

    // const carryOverDocument = async (document, userID, name) => {
    //     const formData = new FormData();
    //     formData.append('file', document);
    //     formData.append('user_id', userID);
    //     formData.append('file_name', name);
    //     console.log("carryOverDocument", formData)
    //     try {
    //         const response = await axios.post(SERVER_IP + '/pdf', formData);
    //     } catch (error) {
    //         console.error('Error uploading the document:', error);
    //     }
    // }

    const login = async (newUserID) => {
        localStorage.setItem("userID", newUserID);
        setUserID(newUserID);
        setIsLoggedIn(true);

        getFeatureFlag(newUserID, "show_user_rewardful_popup_june_3", serverIPs.SERVER_IP)
            .then(response => {
                console.log("GET FEATURE FLAG RESPONSE", response);
                if (response !== null) {
                    dispatch(setIsNewFeatureOpen(response.feature_flag))
                } else {
                    dispatch(setIsNewFeatureOpen(false));
                    setFeatureFlag(newUserID, "show_user_rewardful_popup_june_3",
                        true, serverIPs.SERVER_IP).then(r => {console.log("Feature flag set to true", r)})
                }
            })
            .catch(error => {
                console.error("Error fetching feature flag", error);
            })
            .finally(() => {
            });
        checkProPlan(newUserID, serverIPs.SERVER_IP).then(r => {
            dispatch(setIsProPlan(r.isProPlan));
            localStorage.setItem("isProPlan", r.isProPlan.toString());

            if (r.isProPlanByDomain) {
                localStorage.setItem("isProPlanByDomain", r.isProPlanByDomain.toString());
            } else {
                localStorage.setItem("isProPlanByDomain", "false");
            }
        })


        if (getDocumentsInStorage().length === 1 || getDocumentsInStorageVideo().length === 1) {
            if (getDocumentsInStorage().length === 1) {
                const fileID = getDocumentsInStorage()[0].id;
                transferFromGuest(newUserID, fileID, serverIPs.SERVER_IP).then(r => {
                    fetchDocuments(newUserID, serverIPs.SERVER_IP).then(r => {
                        dispatch(setDocuments(r.documents));
                        window.location.reload();
                    })
                });
            }
            if (getDocumentsInStorageVideo().length === 1) {
                const fileID = getDocumentsInStorageVideo()[0].id;
                transferFromGuestVideo(newUserID, fileID, serverIPs.SERVER_IP_VIDEO).then(r => {
                    fetchVideos(newUserID, serverIPs.SERVER_IP_VIDEO).then(r => {
                        dispatch(setVideos(r.videos));
                        window.location.reload();
                    })
                });
            }
        } else {
            fetchDocuments(newUserID, serverIPs.SERVER_IP).then(r => {
                dispatch(setDocuments(r.documents));
            })
            fetchVideos(newUserID, serverIPs.SERVER_IP_VIDEO).then(r => {
                dispatch(setVideos(r.videos));
            })
            fetchMultidocs(newUserID, serverIPs.SERVER_IP_MULTIDOC).then(r => {
                dispatch(setDocumentsGeneration(r.multidocs));
            })
        }

        //console.log("before if statement")
        // if (getDocumentsInStorage().length === 1) {
        //     console.log("if statement in === 1")
        //     const theDocument = getDocumentsInStorage()[0];
        //     console.log("theDocument", theDocument)
        //     console.log("newUserID", newUserID)
        //     carryOverDocument(theDocument.document, newUserID, theDocument.name).then(r => {
        //         fetchDocuments(newUserID).then(r => {
        //             dispatch(setDocuments(r.documents));
        //         })
        //     });
        // } else {
        //     fetchDocuments(newUserID).then(r => {
        //         dispatch(setDocuments(r.documents));
        //     })
        // }
    };

    const logout = () => {
        localStorage.removeItem("userID")
        setIsLoggedIn(false);
        setUserID(null);
        setPlaygroundDocuments(getDocumentsInStorage());
        dispatch(setDocuments(getDocumentsInStorage()));
        setPlaygroundDocumentsVideo(getDocumentsInStorageVideo());
        dispatch(setVideos(getDocumentsInStorageVideo()));
        setPlaygroundDocumentsGeneration(getDocumentsInStorageGeneration());
        dispatch(setDocumentsGeneration(getDocumentsInStorageGeneration()));
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, userID, login, logout, isThinking, setIsThinking,
            playgroundDocuments, setPlaygroundDocuments, playgroundDocumentsGeneration, setPlaygroundDocumentsGeneration,
            playgroundDocumentsVideo, setPlaygroundDocumentsVideo,
            isTyping, setIsTyping, isThinkingGeneration, setIsThinkingGeneration,
            isTypingGeneration, setIsTypingGeneration, isThinkingVideo, setIsThinkingVideo, isTypingVideo, setIsTypingVideo }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
