import {
    Avatar,
    Box,
    HStack,
    Menu,
    Button,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
    Tooltip,
    Image,
    useColorMode,
    Flex, IconButton, useDisclosure, useBreakpointValue, VStack, Drawer, DrawerOverlay, DrawerContent, Badge
} from "@chakra-ui/react"
import {ChevronDownIcon, HamburgerIcon} from "@chakra-ui/icons"
import {useNavigate} from "react-router-dom"
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentPageGrid, toggleSidePanel} from "../../redux/reducers";
import SignUpScreen1 from "../Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../Modals/SignIn/SignInScreen1";
import {useAuth} from "../../redux/AuthProvider";
import {BsFillPersonFill} from "react-icons/bs";
import DeleteAccount from "../Modals/DeleteModal/DeleteAccount";
import {FiStar} from "react-icons/fi";
import {getDocumentsInStorage} from "../../utils/local-storage-helpers";
import SearchBar from "./SearchBar";
import EditTitleGeneration from "./EditTitleGeneration";
import {manageSubscription} from "../../utils/redux-helpers";
import {useTranslation} from "react-i18next";
import {useServerIP} from "../../redux/ServerIPContext";

export default function NavbarGeneration({onToggleSidePanel=null, profileImage, firstName, lastName, showSearchBar = true,
                                   navigateToTestimonials=null, navigateToFeatures=null, generationTitle, generation}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {colorMode, toggleColorMode} = useColorMode()
    const links = [
        {title: 'Honey Bear', href: '/'},
    ];
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const { isOpen: isOpenDelete1, onOpen: onOpenDelete1, onClose: onCloseDelete1 } = useDisclosure();
    const auth = useAuth();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDrawerOpen, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();
    const documents = useSelector((state) => state.documents.savedDocuments);
    const isProPlan = useSelector((state) => state.settings.isProPlan);
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const buttonStyle = {
        background: 'transparent',
        color: 'initial',
        fontWeight: '500',
        paddingX: '1rem',
        paddingY: '0.5rem',
        _hover: {
            background: 'transparent',
            color: 'blue',
        },
    };

    const navButtonStyle= {
        background: 'transparent', // Ensures no background
        color: 'initial', // Initial color
        _hover: {
            background: 'transparent',
            color: 'blue',
        },
    };

    const DrawerMenu = () => (
        <Drawer isOpen={isDrawerOpen} placement="left" onClose={onCloseDrawer}>
            <DrawerOverlay>
                <DrawerContent>
                    <VStack p={5} spacing={4} align={'flex-start'}>
                        {/* Add drawer menu items here */}
                        <Button
                            onClick={() => {
                                navigateToFeatures();
                                onCloseDrawer();
                            }}
                            height={'100%'}
                            sx={buttonStyle}>
                            {t("Features")}
                        </Button>
                        <Button
                            onClick={() => {
                                navigateToTestimonials();
                                onCloseDrawer();
                            }}
                            height={'100%'}
                            sx={buttonStyle}>
                            {t("Testimonials")}
                        </Button>
                        <Button
                            onClick={() => {
                                window.open('https://forms.gle/eGhBU5LbJ4HStiPv9', '_blank');
                                onCloseDrawer();
                            }}
                            height={'100%'}
                            sx={buttonStyle}>
                            {t("Share")}
                        </Button>
                        {/*<Button*/}
                        {/*    onClick={() => {*/}
                        {/*        onOpenSignUpScreen1();*/}
                        {/*    }}*/}
                        {/*    height={'100%'}*/}
                        {/*    sx={buttonStyle}>*/}
                        {/*    Sign Up*/}
                        {/*</Button>*/}
                    </VStack>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );

    return (
        <div>
            <Flex borderBottomWidth={0.2} position="fixed" top={0} width='100%' px={'0.8rem'}
                  as='header' backgroundColor={colorMode === 'dark' ? 'black' : 'white'} zIndex='1000'
                  height={isMobile? '3.5rem' : '4rem'} alignItems={"center"}>
                {!isMobile ? <Flex flexGrow={1} width={auth.isLoggedIn ? "33%" : "45%"} alignItems="center" justifyContent="flex-start">
                        {/*{auth.isLoggedIn ?*/}
                        {/*    <IconButton*/}
                        {/*        aria-label="Menu"*/}
                        {/*        icon={<HamburgerIcon />}*/}
                        {/*        variant="ghost"*/}
                        {/*        sx={{*/}
                        {/*            _hover: {*/}
                        {/*                background: 'transparent',*/}
                        {/*            },*/}
                        {/*        }}*/}
                        {/*        onClick={() => {*/}
                        {/*            if (onToggleSidePanel !== null) {*/}
                        {/*                onToggleSidePanel();*/}
                        {/*            } else {*/}
                        {/*                dispatch(toggleSidePanel())*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*    /> : null}*/}
                        <a href={localStorage.getItem("feature") === 'vid' ? "https://www.honeybear.ai/vid/?tab=multidoc" : "https://www.honeybear.ai/?tab=multidoc"}
                           rel="noopener noreferrer">
                            <Image
                                src="/Official_Logo_Honeybear.jpg"
                                alt="Logo"
                                height="34px"
                                ml={auth.isLoggedIn ? "0.8rem" : "0.3rem"}
                            />
                        </a>
                        {auth.isLoggedIn && JSON.parse(localStorage.getItem('isProPlan')) && !JSON.parse(localStorage.getItem("isProPlanByDomain")) ?
                            <Badge
                                colorScheme="purple"
                                variant="solid"
                                fontSize="0.7em"
                                borderRadius="5"
                                px="2"
                                py="1"
                                ml={2}
                                mt={'1px'}
                                boxShadow="0px 1px 6px -1px rgba(0, 0, 0, 0.1), 0px 1px 6px -1px rgba(0, 0, 0, 0.1)"
                            >
                                Pro
                            </Badge>
                            : null}
                    </Flex> :

                    <Flex flexGrow={1} width={auth.isLoggedIn ? "33%" : "45%"} alignItems="center" justifyContent="flex-start">
                        <a href={localStorage.getItem("feature") === 'vid' ? "https://www.honeybear.ai/vid/?tab=multidoc" : "https://www.honeybear.ai/?tab=multidoc"}
                           rel="noopener noreferrer">
                            <Image
                                src="/Official_Logo_Honeybear.jpg"
                                alt="Logo"
                                height="34px" ml={auth.isLoggedIn && !isMobile ? "0.8rem" : "0.3rem"}
                            />
                        </a>
                    </Flex>}

                {!isMobile ? <Flex flexGrow={1} justifyContent="center" width={!isMobile && auth.isLoggedIn ? "33%" : "55%"}>
                        {auth.isLoggedIn && (documents !== null && documents.length > 0) ?

                            <EditTitleGeneration generationTitle={generationTitle} generation={generation}/>

                             : null}
                    </Flex>
                    : null}

                <Flex flexGrow={1} justifyContent="flex-end" width={!isMobile && auth.isLoggedIn ? "33%" : "55%"} bg={'white'}
                      height={'100%'} alignItems={'center'}>
                    {!auth.isLoggedIn && !isMobile && localStorage.getItem("documents") !== null && getDocumentsInStorage().length === 0 ?
                        <Button
                            onClick={navigateToFeatures}
                            height={'100%'}
                            sx={navButtonStyle}>
                            {t("Features")}
                        </Button>
                        : null}
                    {!auth.isLoggedIn && !isMobile && localStorage.getItem("documents") !== null && getDocumentsInStorage().length === 0 ?
                        <Button
                            onClick={navigateToTestimonials}
                            height={'100%'}
                            sx={navButtonStyle}>
                            {t("Testimonials")}
                        </Button>
                        : null}
                    {!isMobile && ((localStorage.getItem("documents") !== null && (getDocumentsInStorage().length !== 0)) || (documents !== null && documents.length > 0)) ?
                        <Button
                            onClick={() => {
                                navigate('/?tab=multidoc');
                            }}
                            height={'100%'}
                            sx={navButtonStyle}>
                            {t("Home")}
                        </Button>
                        : null}
                    {!isMobile ?
                        <Button
                            onClick={() => {
                                navigate('/pricing');
                            }}
                            height={'100%'}
                            sx={navButtonStyle}>
                            {t("Pricing")}
                        </Button>
                        : null}
                    {!isMobile ?
                        <Button
                            onClick={() => {
                                navigate('/affiliate');
                            }}
                            height={'100%'}
                            sx={navButtonStyle}>
                            {t("Affiliate")}
                        </Button>
                        : null}
                    <Menu>
                        {auth.isLoggedIn ?
                            <MenuButton as={Button}
                                        variant="subtle"
                                        height={'100%'}
                                        background={'transparent'}
                                        leftIcon={
                                            auth.isLoggedIn ?
                                                <Avatar name={firstName + " " + lastName} size={"sm"}/> :
                                                <BsFillPersonFill size={'1.5rem'}/>
                                        }>
                                {auth.isLoggedIn && !isMobile ?
                                    <Text ml={'0.2rem'}>{firstName}</Text> : null}
                            </MenuButton> :
                            !isMobile ?
                                <Button
                                    alignSelf={"center"}
                                    rounded={'full'}
                                    px={6}
                                    ml={'0.75rem'}
                                    colorScheme={'orange'}
                                    bg={'orange.400'}
                                    _hover={{ bg: 'orange.500' }}
                                    onClick={onOpenSignUpScreen1}>
                                    {t("Sign Up")}
                                </Button> :
                                <IconButton
                                    aria-label="Open Menu"
                                    icon={<HamburgerIcon />}
                                    onClick={onOpenDrawer}
                                    variant="ghost"
                                />

                        }
                        <MenuList minWidth={'25rem'}>
                            <Box px={7} py={5} borderBottomWidth="1px" mb={3}>
                                <HStack spacing={5} align={'flex-start'}>
                                    <Avatar name={firstName + " " + lastName} size="lg" />
                                    <VStack align={'flex-start'} spacing={3}>
                                        <Text fontWeight="bold" fontSize="lg">{firstName} {lastName}</Text>
                                        <Text fontSize="md">{localStorage.getItem("email")}</Text>
                                        {JSON.parse(localStorage.getItem('isProPlan')) && !JSON.parse(localStorage.getItem("isProPlanByDomain")) ?
                                            <Text fontSize="md" fontWeight="bold" color={'orange.500'}>Pro Plan</Text> :
                                            <Text fontSize="md" fontWeight="bold" color={'black'}>{t("Free Plan")}</Text>}
                                    </VStack>
                                </HStack>
                                {/* Add other user-related info here */}
                            </Box>
                            {auth.isLoggedIn && JSON.parse(localStorage.getItem('isProPlan')) && !JSON.parse(localStorage.getItem("isProPlanByDomain")) ?
                                <MenuItem
                                    sx={buttonStyle}
                                    onClick={() => {
                                        manageSubscription(localStorage.getItem("userID"), serverIPs.SERVER_IP).then(r => {
                                            window.location.href = r.url;
                                        })
                                    }} >{t("Manage Subscription")}</MenuItem> :
                                auth.isLoggedIn && (!JSON.parse(localStorage.getItem('isProPlan')) || JSON.parse(localStorage.getItem("isProPlanByDomain"))) ?
                                    <MenuItem
                                        sx={buttonStyle}
                                        onClick={() => {
                                            navigate('/pricing');
                                        }} >{t("Upgrade to Pro")}</MenuItem> : null}

                            {/*{auth.isLoggedIn ?*/}
                            {/*    <MenuItem*/}
                            {/*        sx={buttonStyle}*/}
                            {/*        onClick={() => {*/}
                            {/*            window.location.href = "https://forms.gle/Km9j2id9ouPHYKHc9";*/}
                            {/*        }} >Get Paid to Refer Users</MenuItem> : null}*/}

                            {auth.isLoggedIn ?
                                <MenuItem
                                    sx={buttonStyle}
                                    onClick={() => {
                                        dispatch(setCurrentPageGrid(1));
                                        auth.logout();
                                        navigate('/')
                                    }} >{t("Sign Out")}</MenuItem> :
                                <MenuItem onClick={onOpenSignUpScreen1}>{t("Sign Up")}</MenuItem>}

                            {auth.isLoggedIn ?
                                <MenuItem
                                    sx={buttonStyle}
                                    onClick={() => {
                                        onOpenDelete1();
                                    }}>
                                    {t("Delete Account")}
                                </MenuItem> : null}
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
            <DrawerMenu/>
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <DeleteAccount onCloseDelete1={onCloseDelete1} isOpenDelete1={isOpenDelete1} userID={localStorage.getItem("userID")}/>
        </div>
    );

}










