import * as pdfjsLib from 'pdfjs-dist';
import axios from "axios";
import {SERVER_IP, SERVER_IP_VIDEO} from "./constants";
import {v4 as uuidv4} from "uuid";
import {getClaudeResponse} from "./api";

// Point to the worker source with correct version
pdfjsLib.GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js"

// Function to fetch and create PDFDocumentProxy from a url
export const fetchPDFDocumentProxy = async (url) => {
    try {
        // Loading a document.
        const loadingTask = pdfjsLib.getDocument(url);

        // Wait for the document to be loaded
        const pdfDocument = await loadingTask.promise;

        // Now pdfDocument is a PDFDocumentProxy object.
        console.log('PDF loaded', pdfDocument);
        return pdfDocument;
    } catch (err) {
        console.error('Error loading PDF:', err);

        if (err.name === "UnexpectedResponseException") {
            alert("Your PDF has expired in guest mode. Please sign up for free, unlimited access.")
        }
    }
}


// export const getNextId = () => String(Math.random()).slice(2);

export const parseIdFromHash = () =>
    document.location.hash.slice("#highlight-".length);

export const resetHash = () => {
    document.location.hash = "";
};

export const addHighlightHelper = (highlights, newHighlight, highlightID) => {
    console.log("Saving highlight", newHighlight);
    return [{ ...newHighlight, id: highlightID }, ...highlights];
};

export const updateHighlightHelper = (highlights, highlightId, position, content) => {
    console.log("Updating highlight", highlightId, position, content);
    return highlights.map((h) => {
        const { id, position: originalPosition, content: originalContent, ...rest } = h;
        return id === highlightId
            ? {
                id,
                position: { ...originalPosition, ...position },
                content: { ...originalContent, ...content },
                ...rest,
            }
            : h;
    });
};

// function vhToRem(vhValue) {
//     // Get the viewport height in pixels
//     const viewportHeight = window.innerHeight;
//
//     // Calculate the pixel value for the provided vh value
//     const pxValue = (vhValue / 100) * viewportHeight;
//
//     // Get the root font size (usually set to 16px in most browsers)
//     const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
//
//     // Convert the pixel value to rem
//     const remValue = pxValue / rootFontSize;
//
//     return remValue;
// }

export const whichMessageHeader = (type, message) => {
    switch (type) {
        case "explain_selection":
            return "Explain the Selection";
        case "summarize_selection":
            return "Summarize the Selection";
        case "ask_selection":
            return message.question;
        case "ask_image":
            return message.question;
        case "summarize_page":
            return "Summarize this Page";
        case "summarize_paper":
            return "Summarize this PDF";
        case "general_question":
            return "";
        case "follow_up":
            return message.question;
    }
}

export const createThumbnail = async (url) => {
    const pdfDocument = await fetchPDFDocumentProxy(url);

    const page = await pdfDocument.getPage(1);
    const canvas = document.createElement("canvas");
    const viewport = page.getViewport({scale: 0.2});
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    try {
        await page.render({
            canvasContext: canvas.getContext("2d"),
            viewport,
        }).promise;
    } catch (e) {
        if (e.name === 'RenderingCancelledException') {
            console.warn('Rendering was cancelled');
        } else {
            console.error(e);
        }
    }
    return canvas.toDataURL();
}

export const getSessionToken = async (file_id, user_id, socketID, serverIP) => {
    console.log("Getting session token for file id", file_id.toString());
    console.log("Getting socketID for START_SESSION SINGLE DOC", socketID);
    // response.data.session_token or response.data.url
    try {
        const response = await axios.post(serverIP + '/start_session', {
            "file_id": file_id.toString(),
            "user_id": user_id.toString(),
            "socketio_sid": socketID,
        });
        console.log("response for start session", response)
        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.status === 200) {
            return {
                "status" : 200,
                "sessionToken" : response.data.session_token,
                "doc_metadata" : response.data.doc_metadata,
            }
        }
    } catch (error) {
        console.log("/start_session error", error);
        return null;
    }
}

export const getSessionTokenVideo = async (file_id, user_id, serverIP) => {
    console.log("Getting session token for video id", file_id.toString());
    // response.data.session_token or response.data.url
    try {
        const response = await axios.post(serverIP + '/videos/start_session', {
            "file_id": file_id.toString(),
            "user_id": user_id.toString(),
        });
        console.log("response for start session VIDEO", response)
        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.status === 200) {
            return {
                "status" : 200,
                "sessionToken" : response.data.session_token,
                "doc_metadata" : response.data.doc_metadata,
            }
        }
    } catch (error) {
        console.log("/start_session VIDEO error", error);
        return null;
    }
}

export const getSessionTokenGeneration = async (generation_id, user_id, serverIP) => {
    console.log("Getting session token for generation id", generation_id.toString());
    // response.data.session_token or response.data.url
    try {
        const response = await axios.post(serverIP + '/pdf/get_generation', {
            "generation_id": generation_id.toString(),
            "user_id": user_id.toString(),
        });
        console.log("response for get_generation", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.status === 200) {
            return {
                "status" : 200,
                "id": response.data.message.generationID,
                "name": response.data.message.generationTitle,
                "messages": response.data.message.generations,
                "files": response.data.message.sources,
            }
        }
    } catch (error) {
        console.log("/get_generation error", error);
        return null;
    }
}

export const initializeGeneration = async (generation_title, user_id, serverIP) => {
    try {
        const response = await axios.post(serverIP + '/pdf/initialize_generation', {
            "generation_title": generation_title.toString(),
            "user_id": user_id.toString(),
        });
        console.log("response for initialize_generation", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.status === 200) {
            return {
                "status" : 200,
                "id": response.data.message.generationID,
                // "id": response.data.message.generationID,
                // "name": response.data.message.generationTitle,
                // "messages": response.data.message.generations,
                // "files": response.data.message.sources,
            }
        }
    } catch (error) {
        console.log("/initialize_generation error", error);
        return null;
    }
}

export const createGenerationSession = async (generation_id, user_id, serverIP) => {
    try {
        const response = await axios.post(serverIP + '/pdf/create_generation_session', {
            "generation_id": generation_id.toString(),
            "user_id": user_id.toString(),
        });
        console.log("response for create_generation_session", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "generationSessionToken": response.data.message.generation_session_token,
                // "id": response.data.message.generationID,
                // "name": response.data.message.generationTitle,
                // "messages": response.data.message.generations,
                // "files": response.data.message.sources,
            }
        }
    } catch (error) {
        console.log("/create_generation_session error", error);
        return null;
    }
}

export const generateContent = async (user_id, generation_session_token, prompt, word_count, should_reference_sources,
                                      socketID, toast, serverIP) => {
    console.log("generate content request body", {
        "user_id": user_id.toString(),
        "generation_session_token": generation_session_token.toString(),
        "prompt": prompt.toString(),
        "word_count": word_count,
        "should_reference_sources": should_reference_sources,
        "socketio_sid": socketID,
    })

    try {
        const response = await axios.post(serverIP + '/pdf/generate', {
            "user_id": user_id.toString(),
            "generation_session_token": generation_session_token.toString(),
            "prompt": prompt.toString(),
            "word_count": word_count,
            "should_reference_sources": should_reference_sources,
            "socketio_sid": socketID,
        });
        console.log("response for /generate", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.data.status === 402) {
            toast({
                title: "(Free Plan) Chat Limit Exceeded!",
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            return {
                "status" : 402,
                "generationSessionToken": "",
                "celeryTaskID": "",
            }
        }
        else if (response.data.status === 200 && response.data.message.message === "call_celery_respond_without_source_peek task dispatched, poll for updates") {
            return {
                "status" : 200,
                "generationSessionToken": "",
                "celeryTaskID": response.data.message.task_id,
            }
        }
        else if (response.data.status === 200 && response.data.message.message === "map_synthesize task dispatched, poll for updates") {
            return {
                "status" : 201,
                "generationSessionToken": "",
                "celeryTaskID": response.data.message.task_id,
            }
        }
        else {
            return {
                "status" : 202,
                "generationSessionToken": response.data.message.generation_session_token,
                "celeryTaskID": response.data.message.celery_task_id,
            }
        }
    } catch (error) {
        console.log("/generate error", error);
        return null;
    }
}

export const uploadHighlight = async (file_id, user_id, highlight_data, serverIP) => {
    // console.log("upload highlight request body", {
    //     "file_id": file_id.toString(),
    //     "user_id": user_id.toString(),
    //     "highlight_data": highlight_data,
    // })

    const requestBody = {
        "file_id": file_id.toString(),
        "user_id": user_id.toString(),
        "highlight_data": highlight_data,
    }
    console.log("upload highlight request body", requestBody)

    try {
        const response = await axios.post(serverIP + '/pdf/highlights', requestBody);
        console.log("response for upload highlight", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        }
    } catch (error) {
        console.log("upload highlight error", error);
        return null;
    }
}

export const deleteHighlightHelper = async (file_id, user_id, highlight_data_to_remove, serverIP) => {
    // console.log("delete highlight request body", {
    //     "file_id": file_id.toString(),
    //     "user_id": user_id.toString(),
    //     "highlight_data_to_remove": highlight_data_to_remove,
    // })

    try {
        const response = await axios.post(serverIP + '/pdf/highlights', {
            "file_id": file_id.toString(),
            "user_id": user_id.toString(),
            "highlight_data_to_remove": highlight_data_to_remove,
        });
        console.log("response for delete highlight", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        }
    } catch (error) {
        console.log("delete highlight error", error);
        return null;
    }
}

// export const handleChatResponse = (
//     type,
//     fullContent,
//     sessionToken,
//     socketID,
//     questionText,
//     addMessageInDocument,
//     setIsTyping,
//     updateMessageInDocument,
//     socket,
//     documentID
// ) => {
//     const userID = uuidv4();
//     const claudeID = uuidv4();
//     socket.off('receive_message');
//     addMessageInDocument(
//         {
//             messageToAdd: {id: userID, type:'Explain', user: 'User', text: fullContent.content.text, questionText: questionText},
//             documentID: documentID,
//         }
//     );
//     addMessageInDocument(
//         {
//             messageToAdd: {id: claudeID, type:'Explain', user: 'Claude', text: "", questionText: questionText},
//             documentID: documentID,
//         }
//     );
//     console.log("FULL CONTENT", fullContent);
//     setIsTyping(true);
//     getClaudeResponse(sessionToken, fullContent.position.pageNumber - 1, fullContent.content.text,
//         socketID, questionText, type)
//         .then(responseText => {
//             socket.off('receive_message');
//         })
//         .catch(error => {
//             socket.off('receive_message');
//             console.error("Error getting Claude response:", error);
//         });
//
//     socket.on('receive_message', function(data) {
//         setIsTyping(false);
//         console.log("received data", data);
//         updateMessageInDocument({messageID: claudeID, user:'Claude', text: data.message,
//             questionText: questionText, documentID: documentID});
//     }.bind(this));
// };





















