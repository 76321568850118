'use client'

import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button, useToast, useDisclosure, useBreakpointValue,
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import React, {useEffect, useState} from "react";
import FullScreenTemplate from "../../templates/FullScreenTemplate";
import FAQ from "./FAQ";
import {createCheckoutSession} from "../../utils/payments";
import {useAuth} from "../../redux/AuthProvider";
import {manageSubscription} from "../../utils/redux-helpers";
import LargeWithNewsletter from "../../components/Reuseable/LargeWithNewsletter";
import SignUpScreen1 from "../../components/Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../../components/Modals/SignIn/SignInScreen1";
import {useTranslation} from "react-i18next";
import LogoCarousel2 from "../../components/Reuseable/LogoCarousel2";
import { IoMdCheckmark } from "react-icons/io";
import {influencerMap} from "../../utils/constants";
import {useServerIP} from "../../redux/ServerIPContext";


function PriceWrapper(props) {
    const { children } = props

    return (
        <Box
            mb={0}
            shadow="base"
            borderWidth="1px"
            height={'27rem'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            borderColor={useColorModeValue('gray.200', 'gray.500')}
            borderRadius={'xl'}>
            {children}
        </Box>
    )
}

export default function ThreeTierPricing() {
    const { serverIPs, setServerIPs, isDev } = useServerIP();
    const auth = useAuth();
    const {t} = useTranslation();
    const [premiumLoading, setPremiumLoading] = useState(false);
    const [freeLoading, setFreeLoading] = useState(false);
    const [manageSubscriptionLoading, setManageSubscriptionLoading] = useState(false);
    const toast = useToast();
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const deadline = new Date('2025-02-04T23:59:00-05:00');
    const now = new Date();

    useEffect(() => {
        console.log("pricing_page_shown")
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <FullScreenTemplate>
                <VStack py={10} spacing={10} width={'100%'}>
                    <VStack spacing={2} textAlign="center">
                        <Heading as="h1" fontSize="5xl" fontWeight="600">
                            {t("Simple")},{' '}
                            <Box
                                as="span"
                                bgGradient="linear(to-r, purple.500, pink.500)"
                                bgClip="text"
                            >
                                {t("transparent pricing")}
                            </Box>
                        </Heading>
                        <Text fontSize={21} color={'black'} mt={4} fontWeight="500">
                            {t("Unlimited access. Cancel anytime.")}
                        </Text>
                        {now < deadline ?
                            <Text color={'black'} mt={4} fontWeight="500">
                                <Text as="span" fontSize={15}>Limited-time Offer:</Text>{' '}
                                <Text as="span" fontSize={18} fontWeight={'bold'}>HONEY2025</Text>{' '}
                                <Text as="span" fontSize={18}>for 50% Off your first month</Text>
                            </Text>
                            :
                            null}
                    </VStack>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        textAlign="center"
                        justify="center"
                        spacing={{ base: 9, lg: 9 }}
                        mt={-5}
                        py={10}>
                        <PriceWrapper>
                            <Box px={6} py={5} height={'40%'} textAlign="left">
                                <Text fontWeight="500" fontSize={28}>
                                    Free
                                </Text>
                                <HStack spacing={1} mt={-1.5}>
                                    <Text fontSize="3xl" fontWeight="500">
                                        $
                                    </Text>
                                    <Text fontSize="5xl" fontWeight="600">
                                        0
                                    </Text>
                                </HStack>
                                <Text fontWeight="400" color={'gray'} fontSize={16}>
                                    {t("Forever")}
                                </Text>
                            </Box>
                            <VStack
                                bg={'white'}
                                borderTopColor={'lightgray'}
                                borderTopWidth={1}
                                py={4}
                                pt={7}
                                mx={6}
                                justify={'space-between'}
                                height={'60%'}
                                borderBottomRadius={'xl'}>
                                <List spacing={3} textAlign="start">
                                    <ListItem fontSize={15.5}>
                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                        {t("13 total chats")}
                                    </ListItem>
                                    <ListItem fontSize={15.5}>
                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                        {t("13 total PDF/video uploads")}
                                    </ListItem>
                                    <ListItem fontSize={15.5}>
                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                        {t("Up to 20 pages for scanned PDFs")}
                                    </ListItem>
                                </List>
                                {auth.isLoggedIn && JSON.parse(localStorage.getItem("isProPlan")) && !JSON.parse(localStorage.getItem("isProPlanByDomain")) ?
                                    null
                                    :
                                    <Box w="100%" pt={0}>
                                        <Button w="full" color={'gray'} borderColor={'gray'} variant="outline" _hover={{ cursor: "not-allowed" }}>
                                            {t("Your current plan")}
                                        </Button>
                                    </Box>}
                            </VStack>
                        </PriceWrapper>
                        {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <PriceWrapper>
                            <Box px={6} py={5} height={'40%'} textAlign="left">
                                <Text fontWeight="500" fontSize={28}>
                                    Pro
                                </Text>
                                <HStack spacing={1} mt={-1.5}>
                                    <Text fontSize="3xl" fontWeight="500">
                                        $
                                    </Text>
                                    <Text fontSize="5xl" fontWeight="600">
                                        10
                                    </Text>
                                </HStack>
                                <Text fontWeight="400" color={'gray'} fontSize={16}>
                                    {t("Per Month, billed monthly")}
                                </Text>
                            </Box>
                            <VStack
                                bg={'white'}
                                borderTopColor={'lightgray'}
                                borderTopWidth={1}
                                py={4}
                                pt={7}
                                mx={6}
                                justify={'space-between'}
                                height={'60%'}
                                borderBottomRadius={'xl'}>
                                <List spacing={3} textAlign="start">
                                    <ListItem fontSize={15.5}>
                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                        {t("Unlimited chats")}
                                    </ListItem>
                                    <ListItem fontSize={15.5}>
                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                        {t("Unlimited PDF/video uploads")}
                                    </ListItem>
                                    <ListItem fontSize={15.5}>
                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                        {t("Up to 200 pages for scanned PDFs")}
                                    </ListItem>
                                    <ListItem fontSize={15.5}>
                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                        {t("Access to future Pro features")}
                                    </ListItem>
                                </List>
                                {auth.isLoggedIn && JSON.parse(localStorage.getItem("isProPlan")) && !JSON.parse(localStorage.getItem("isProPlanByDomain")) ?
                                    <Box w="100%" pt={0}>
                                        <Button w="full"
                                                bg="black"
                                                color="white"
                                                borderColor="black"
                                                variant="solid"
                                                _hover={{ bg: "gray.700" }}
                                                isLoading={manageSubscriptionLoading}
                                                isDisabled={manageSubscriptionLoading}
                                                onClick={async () => {
                                                    setManageSubscriptionLoading(true)
                                                    manageSubscription(localStorage.getItem("userID"), serverIPs.SERVER_IP).then(r => {
                                                        setManageSubscriptionLoading(false)
                                                        window.location.href = r.url;
                                                    })
                                                }}>
                                            {t("Manage Subscription")}
                                        </Button>
                                    </Box>
                                    :
                                    <Box w="100%" pt={0}>
                                        <Button w="full"
                                                bg="black"
                                                color="white"
                                                borderColor="black"
                                                variant="solid"
                                                _hover={{ bg: "gray.700" }}
                                                isLoading={premiumLoading}
                                                isDisabled={premiumLoading}
                                                onClick={async () => {
                                                    console.log("subscribe_button_clicked");
                                                    setPremiumLoading(true)
                                                    const userID = auth.isLoggedIn ? localStorage.getItem("userID")
                                                        : "11111111-1111-1111-1111-111111111111"
                                                    const userEmail = localStorage.getItem("email")

                                                    if (userID === "11111111-1111-1111-1111-111111111111") {
                                                        setPremiumLoading(false)
                                                        onOpenSignUpScreen1();
                                                        toast({
                                                            title: t("Please sign up before subscribing!"),
                                                            description: t("You must be signed in to subscribe to a plan"),
                                                            status: "info",
                                                            duration: 9000,
                                                            isClosable: true,
                                                            position: "top",
                                                        });
                                                    } else {
                                                        await createCheckoutSession(userID, userEmail, serverIPs.SERVER_IP).then(r => {
                                                            setPremiumLoading(false)
                                                            window.location.href = r.url;
                                                            // if (isMobile) {
                                                            //     // Navigate directly in the same window for mobile users
                                                            //     window.location.href = r.url;
                                                            // } else {
                                                            //     // For desktop, open a new tab as before
                                                            //     window.open(r.url, '_blank', 'noopener,noreferrer');
                                                            // }
                                                        })
                                                    }

                                                }}>
                                            {t("Upgrade")}
                                        </Button>
                                    </Box>
                                }
                            </VStack>
                        </PriceWrapper>
                    </Stack>
                    <Box mt={-2}>
                        <LogoCarousel2/>
                    </Box>
                    <Box mt={-3} width={'100%'}>
                        <FAQ/>
                    </Box>
                </VStack>
                <Box height="auto" width="100%">
                    <LargeWithNewsletter />
                </Box>
            </FullScreenTemplate>
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
        </div>
    )
}
