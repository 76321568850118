import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import PDFPage from "./PDFPage";
import {io} from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";
import {SERVER_IP, SERVER_IP_SOCKET} from "../../utils/constants";
import {getSessionToken, getSessionTokenVideo} from "../../utils/pdf-page-helpers";
import {useAuth} from "../../redux/AuthProvider";
import {
    setHighlightsInDocument,
    setMessagesInDocument,
    setMessagesInDocumentGeneration,
    setMessagesInVideo
} from "../../redux/reducers";
import {MySpinnerLarge} from "../../components/Reuseable/MySpinner";
import ScanModal1 from "../../components/Modals/Scans/ScanModal1";
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import PDFPageVideo from "./PDFPageVideo";
import {useServerIP} from "../../redux/ServerIPContext";
import {v4 as uuidv4} from "uuid";

export default function WrapperComponentVideo ()  {
    const auth = useAuth();
    const toast = useToast();
    const [socket, setSocket] = useState(null);
    const { id } = useParams();
    const [sessionToken, setSessionToken] = useState("")
    const [currentDocument, setCurrentDocument] = useState(null);
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const documents = useSelector(state => state.documents.savedVideos)
    // const documents = auth.isLoggedIn ? savedDocuments : auth.playgroundDocuments;

    const [forceRender, setForceRender] = useState(0);
    const [socketID, setSocketID] = useState("");
    const { isTypingVideo, setIsTypingVideo } = useAuth();
    const {isThinkingVideo, setIsThinkingVideo} = useAuth();
    const dispatch = useDispatch();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();

    useEffect( () => {
        console.log("In UseEffect WrapperComponent with id:", id);

        const randomID = uuidv4();

        let userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"

        if (userID === "11111111-1111-1111-1111-111111111111") {
            // append the randomID to the userID
            userID = userID + randomID;
        }
        console.log("USERID guest with random ID", userID)

        const newSocket = io(serverIPs.SERVER_IP_SOCKET, {
            query: { user_id: userID }
        });

        // const newSocket = io(serverIPs.SERVER_IP_SOCKET);
        setSocket(newSocket);
        console.log("Socket:", socket)
        newSocket.on('connected', function(data) {
            console.log("Connected:", data);
            setSocketID("user_" + userID);
        });
        return () => {
            console.log("Socket disconnecting", newSocket)
            newSocket.disconnect();
        }
    }, [id])

    useEffect(() => {
        console.log("In UseEffect WrapperComponent with socket:", socket);
        if (documents.length > 0) {
            console.log("Documents length > 0")
            setCurrentDocument(documents.find((document) => document.id === id));
            setForceRender(prevForceRender => prevForceRender + 1);
        }
    }, [documents.length, id]);

    useEffect(() => {
        console.log("In UseEffect WrapperComponent with currentDocument:", currentDocument);
        if (currentDocument) {
            console.log("CurrentDocument is not null")
            const fetchSessionToken = async () => {
                const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
                console.log("USERID", userID)
                const response = await getSessionTokenVideo(id, userID, serverIPs.SERVER_IP_VIDEO);
                if (response) {
                    console.log("RESPONSE FROM START SESSION", response);
                    // if (response.doc_metadata.highlights) {
                    //     dispatch(setHighlightsInDocument({documentID: currentDocument.id,
                    //         newHighlights: response.doc_metadata.highlights.slice().reverse()}));
                    // }
                    if (response.doc_metadata.messages) {
                        dispatch(setMessagesInVideo({documentID: currentDocument.id, newMessages: response.doc_metadata.messages}));
                        // dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
                    }
                    // dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
                    setSessionToken(response.sessionToken);
                }
            };
            fetchSessionToken().then(r => {});
        }
    }, [currentDocument]);

    return (
        sessionToken === "" ?
            <div style={{justifyContent:'center', alignItems:'center', display:'flex', height:'100%', width:'100%', paddingTop:"5rem"}}>
                <MySpinnerLarge/>
            </div>
            :
            <div>
                <PDFPageVideo document={documents.find((document) => document.id === id)}
                         key={forceRender}
                         documents={documents}
                         id={id}
                         isLoggedIn={auth.isLoggedIn}
                         sessionToken={sessionToken}
                         socketID={socketID}
                         socket={socket}
                         isTyping={isTypingVideo} setIsTyping={setIsTypingVideo}
                         isThinking={isThinkingVideo} setIsThinking={setIsThinkingVideo}
                         toast={toast} serverIP={serverIPs.SERVER_IP_VIDEO}/>
                <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                               onClosePricingScreen={onClosePricingScreen}
                               onOpenPricingScreen={onOpenPricingScreen}/>
            </div>
    )
}







